import "core-js/modules/es6.array.of";
import request from '@/utils/request';
export function getMenusTree(pid) {
  return request({
    url: 'api/system/menus/lazy?pid=' + pid,
    method: 'get'
  });
}
export function getMenus(params) {
  return request({
    url: 'api/system/menus',
    method: 'get',
    params: params
  });
}
export function getMenuSuperior(ids) {
  var data = ids.length || ids.length === 0 ? ids : Array.of(ids);
  return request({
    url: 'api/system/menus/superior',
    method: 'post',
    data: data
  });
}
export function getChild(id) {
  return request({
    url: 'api/system/menus/child?id=' + id,
    method: 'get'
  });
}
export function buildMenus() {
  return request({
    url: 'api/system/menus/build',
    method: 'get'
  });
}
export function add(data) {
  return request({
    url: 'api/system/menus',
    method: 'post',
    data: data
  });
}
export function del(ids) {
  return request({
    url: 'api/system/menus',
    method: 'delete',
    data: ids
  });
}
export function edit(data) {
  return request({
    url: 'api/system/menus',
    method: 'put',
    data: data
  });
}
export default {
  add: add,
  edit: edit,
  del: del,
  getMenusTree: getMenusTree,
  getMenuSuperior: getMenuSuperior,
  getMenus: getMenus,
  getChild: getChild
};